import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './Quote.styles';

const Quote = props => {
  const { quote, designVersion, iconColor } = props;
  const { pageDesign } = useContext(FunnelContext);

  return (
    <S.Container
      pageDesign={pageDesign}
      designVersion={designVersion}
      iconColor={iconColor}
    >
      <S.Content
        pageDesign={pageDesign}
        dangerouslySetInnerHTML={{ __html: quote }}
      />
    </S.Container>
  );
};

export default Quote;
